import React from "react";
import { Link } from "gatsby";

const mainStyle = {
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  strong: {
    color: "#db0a5b",
  },
};

const NotFoundPage = props => {
  return (
    <main style={mainStyle}>
      <title>Not found</title>
      <h1 style={{ marginBottom: "2rem" }}>
        4<strong style={{ color: "#db0a5b" }}>oh</strong>4
      </h1>
      <h2 style={{ marginBottom: "1.875rem" }}>Oh cmon! It's not that bad.</h2>
      <p style={{ marginBottom: "1rem" }}>
        We didn't completely break up with you; just this page. We'll work on it!
      </p>
      <Link style={{ color: "#db0a5b", fontWeight: "bold", fontSize: "1rem" }} to="/">
        Back to home
      </Link>
    </main>
  );
};

export default NotFoundPage;
